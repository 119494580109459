<template>
  <span class="">
    <iconlembrar v-if="nivel == 'lembrar'" :size="size" :style="'color: '+color"/>
    <iconentender v-if="nivel == 'entender'" :size="size" :style="'color: '+color"/>
    <iconaplicar v-if="nivel == 'aplicar'" :size="size" :style="'color: '+color"/>
    <iconanalisar v-if="nivel == 'analisar'" :size="size" :style="'color: '+color"/>
    <iconavaliar v-if="nivel == 'avaliar'" :size="size" :style="'color: '+color"/>
    <iconcriar v-if="nivel == 'criar'" :size="size" :style="'color: '+color"/>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "niveisicon",
  components: {
    'iconlembrar': () => import('./icon-lembrar.vue'),
    'iconentender': () => import('./icon-entender.vue'),
    'iconaplicar': () => import('./icon-aplicar.vue'),
    'iconanalisar': () => import('./icon-analisar.vue'),
    'iconavaliar': () => import('./icon-avaliar.vue'),
    'iconcriar': () => import('./icon-criar.vue'),
  },
  props: {
    nivel: { default: "lembrar", type: String },
    size: { default: "w30", type: String },
    color: { default: "#000000", type: String }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
